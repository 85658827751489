<template>
  <div class="textUser">
    <div>
      <div class="top">
        <p
          style="
            font-size: 14pt;
            line-height: 115%;
            margin: 0pt;
            text-align: center;
          "
        >
          <span style="font-family: 宋体; font-size: 14pt; font-weight: bold"
            >东亚畜牧现货产品交易所有限公司</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: center;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt; font-weight: bold"
            >风险告知书</span
          >
        </p>
      </div>
      <div class="content">
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >尊敬的交易用户：</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >东亚畜牧现货产品交易所有限公司(以下简称"交易所”)提供的电子交易系统平台业务存在一定的风险性，在使用交易所电子交易系统平台进行交易过程中所产生的对交易用户的风险承受能力、理解风险的程度、风险控制能力以及现货交易经验有一定的要求，您通过网络页面勾选、点击确认，进行下一步操作即表示已充分认知并了解本告知书载明的全部内容。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >本告知书旨在向交易用户充分揭示交易过程中可能存在的风险，并且帮助交易用户评估和确定自身的风险承受能力。本告知书披露了交易过程中可能发生的各种风险因素，但是并没有完全包括所有关于交易所交易品种的风险。鉴于交易过程中风险的存在，在签署《入市协议书》及进行交易前，交易用户应该仔细研读本风险告知书，必须确保自己理解有关交易的性质、规则，并依据自身的经验、目标、财务状况、承担风险的能力等自行决定是否参与此交易。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >对于本告知书有不理解或不清晰的地方，应该及时咨询相关方，并确保对告知书不存在任何异议。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">一、温馨提示</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            padding-left: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >由于电子交易具有一定的风险性，故不适合使用养老基金、债务资金(如银行贷款、民间贷款)等进行交易的交易用户，交易用户在签署《入市协议书》前请仔细阅读并承诺遵守《东亚畜牧交易所交易用户管理办法（试行）》等文件的相关要求。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >交易所提供的电子交易系统平台业务只适合于满足以下条件的交易用户：</span
          >
        </p>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              padding-left: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(一)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >经行政审批服务局核准登记从事交易相关商品的生产、流通、消费等经营活动的法人、非法人组织、个体工商户，或具有行业背景的自然人交易用户;</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              padding-left: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(二)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >承诺遵守《东亚畜牧交易所预售交易交易规则（试行）》、《东亚畜牧交易所现货挂牌交易规则(试行)》、《东亚畜牧交易所现货竞价交易规则(试行)》、《东亚畜牧交易所交易用户管理办法（试行）》、《东亚畜牧交易所预售交易风险控制管理办法（试行）》等相关配套制度及本告知书;</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(三)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >具有一定资金实力及良好的信誉;</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(四)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >法人单位、非法人组织、个体工商户类交易用户：具有健全的组织架构、财务管理制度及完善的业务管理制度;自然人交易用户：年龄在18至60岁之间，并且具有畜牧行业养殖、加工、销售或其它相关的背景;</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(五)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >经营范围符合国家法律、行政法规和有关政策的规定;</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">(六)</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易所规定的其它条件。</span
            >
          </p>
        </div>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >二、相关的风险揭示</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">(一)政策风险</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            padding-left: 72pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >由于国家法律、法规、政策的变化，紧急措施的出台，相关监管部门监管措施的实施，以及交易所交易规则的修改等原因，均可能会对交易用户的现货交易产生影响，交易用户须承担由此导致的损失。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >(二)价格波动的风险</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            padding-left: 72pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >在电子交易系统进行交易时，假如参与交易的商品价格走势出现波动导致货物升贬值，因而存在出现交易亏损的可能性，如果不能有效控制风险，则可能遭受较大的损失，交易用户必须独自承担由此导致的一切损失。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">(三)资质风险</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            padding-left: 72pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >交易所将严格审核会员服务机构相关信息，对符合交易所要求的方可获得相关资质，可能存在伪造、冒用资质文件或冒充交易所工作人员从而通过诱导开户、代为开户、做出获利承诺、通过直播间或投资群频繁喊单等违规方式，鼓动用户开户并参与交易的情况。上述行为严重破坏交易市场正常秩序，请交易用户仔细核对相关资质文件，如果不能有效辨别真伪，则可能遭受较大的损失，交易用户必须独自承担由此导致的一切损失。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">(四)交易风险</span>
        </p>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 72pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">1.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户需要了解：在交易中如果市场走势对您不利，导致您的履约订金或可用资金不足，<span
                style="text-decoration: underline"
                >交易所会按照约定的时间和方式通知您追加履约订金或可用资金。</span
              >
              交易所发出追加履约订金通知后，<span
                style="text-decoration: underline"
                >交易用户必须在下一个交易日开市前三十分钟内补足至履约订金最低余额为正。</span
              >交易用户必须有条件满足随时追加履约订金的要求，否则其存货将会被代为转让，交易用户必须承担由此造成的全部损失。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">2.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易所及其工作人员不会对交易用户作出获利保证，并且不会与交易用户分享收益或共担风险。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">3.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户的成交单据必须是建立在自己的自主决定之上。交易所及其工作人员提供的任何关于市场的分析和信息，仅供交易用户参考，同时也不构成任何要约。由此而造成的交易风险由交易用户自行承担。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 72pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">4.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >在电子交易的过程中，有可能出现非人为的明显的错误报价，交易所可能事后会对错价及错价产生的盈亏作出纠正，由此而造成的交易风险由交易用户自行承担。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">5.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易期间，如果因交易用户自身原因导致其资产被司法机关采取财产保全或强制执行措施，或者出现破产、解散等情况时，交易用户将面临无法正常交易的风险，由此而造成的交易风险由交易用户自行承担。</span
            >
          </p>
        </div>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >(五)网上交易风险</span
          >
        </p>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">1.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >此业务通过电子通讯技术以及互联网技术来实现。有关通讯服务及软、硬件服务由不同的供应商提供，可能会存在品质和稳定性方面的风险;交易所及其会员服务机构不能控制电讯信号的强弱，也不能保证交易用户客户端的设备配置及其连接的稳定性以及互联网传播和接收的实时性。互联网上的数据传输可能会因通信繁忙出现延迟,或因其它不可抗拒原因出现中断、停顿或数据不完全、数据错误等情况,由此可能导致您的网上交易出现延迟、停顿或中断。造成网上交易的用户无法及时进入交易系统,无法查看行情而影响交易造成的损失由交易用户自行承担。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">2.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户的电脑系统有可能被病毒以及网络黑客攻击，从而使交易用户的决策指令无法正确及时执行。对于上述不确定因素的出现也存在着一定的风险，有可能会对交易用户的交易产生影响，交易用户应该充分了解并承担由此造成的全部损失。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 72pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">3.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >由于不慎将账号、交易密码遗失,账户被他人盗用,所发生的一切损失由交易用户自行承担。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 72pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">4.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >由于网络传输速度的原因,互联网发布的行情信息及其它信息可能会滞后、出现错误并误导交易用户,由此可能导致的损失由交易用户自行承担。</span
            >
          </p>
        </div>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >(六)不可抗力风险</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            padding-left: 72pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >由于疫情、地震、水灾、火灾、暴动、罢工、战争、政府管制、国际或国内的禁止或限制以及停电、技术故障、电子故障等其他无法预测和防范的不可抗力事件，都有可能会对交易用户的交易产生影响，交易用户应该充分了解并自行承担由此造成的全部损失。</span
          >
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 24pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">三、特别提示</span>
        </p>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">1.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户在参与交易之前务必详尽的了解交易品种的基本知识和相关风险以及交易所有关的业务规则等，依法合规地从事商品交易业务。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">2.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易所与现有的国内其他交易所相比，在交易模式、交易规则等方面存在着一定的差别;而且交易所为了确保交易“公开、公平、公正”和健康稳定地发展，将采取更加严格的措施，强化交易过程中的监管。请您务必密切的关注交易所交易行情的公告、风险提醒等信息，及时了解交易风险状况，做到理性交易，切忌盲目跟风。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">3.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户在开通交易之前，请配合交易所了解交易用户的适当性管理规定，完整、如实地提供入市所需要的信息，不得采取弄虚作假等手段规避有关的要求，否则，交易所有权拒绝和停止对其提供交易服务。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">4.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易用户入市需通过交易所用户适当性评估。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
              text-indent: 48pt;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt">5.</span>
          </p>
          <p
            style="
              font-size: 12pt;
              line-height: 115%;
              margin: 0pt;
              text-align: justify;
            "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >交易所诚挚的希望和建议交易用户，从风险承受能力等自身实际情况出发，审慎地决定是否参与交易。</span
            >
          </p>
        </div>
        <div style="display: flex">
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt">6.</span>
          </p>
          <p
            style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
          >
            <span style="font-family: 宋体; font-size: 12pt"
              >本风险告知书的风险揭示事项仅为列举性质，未能详尽列明商品交易的所有风险和可能影响商品合同价格的所有因素。您在参与商品交易前，应认真阅读相关业务规则及相关文件，对商品交易业务所特有的规则必须了解和掌握，全面理解交易规则及实施细则，对自身的经济承受能力、风险控制能力、身体及心理承受能力、理解能力做出客观判断，对交易系统作仔细的、全面的研究，并确信自己已做好足够的风险评估与财务安排，避免因参与商品交易而遭受难以承受的损失。</span
            >
          </p>
        </div>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt">&nbsp;</span
          ><span style="font-family: 宋体; font-size: 12pt">&nbsp;</span
          ><span style="font-family: 宋体; font-size: 12pt">&nbsp;</span>
        </p>
        <p
          style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: justify;
            text-indent: 48pt;
          "
        >
          <span style="font-family: 宋体; font-size: 12pt"
            >本人/本组织已阅读理解和同意以上全部内容，自愿承担上述风险以及由此产生的一切损失。</span
          >
        </p>
        <!-- <div class="btns" id="btns">
          <el-button type="info" @click="cancelBtn">取消</el-button>
          <el-button
            type="primary"
            id="enterBtn"
            @click="enters"
            :disabled="enterDisabled"
            >{{ enterName }}</el-button
          >
        </div> -->
      </div>
      <div class="cnzz" style="display: none"></div>
    </div>
  </div>
</template>
<script>
// import md5 from 'js-md5'
export default {
  name: "UserProtocol",
  components: {},
  data() {
    return {
      enterName: "确认",
      enterDisabled: false,
      time: window.g.AGREEMENT_TIME || 10000,
      interval: null
    };
  },

  mounted() {},

  methods: {
    agreementOpen() {
      var that = this;
      // this.$refs.UserProtocol.agreementOpen;
      // this.dialogVisible1 = true;
      // var time = window.g.AGREEMENT_TIME;
      this.interval = setInterval(() => {
        this.time = Number(this.time) - 1000;
        if (this.time <= 0) {
          that.enterName = "确认";
          clearInterval(interval);
          that.enterDisabled = false;
        } else {
          that.enterDisabled = true;
          that.enterName = `确认(${Number(this.time) / 1000}s)`;
        }
      }, 1000);
    },
    cancelBtn() {
      this.$emit("cancelBtn");
      this.time = window.g.AGREEMENT_TIME;
      if (!this.interval) {
        clearInterval(this.interval);
      }
    },
    iconClick() {
      this.$emit("iconClick");
    },
    enters() {
      this.$emit("enters");
    }
  }
};
</script>

<style lang="scss" scoped>
.textUser span {
  color: #333333 !important;
}
.textUser {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.top {
  position: fixed;
  min-width: 975px;
  width: 1000px;
  margin-top: -119px;
  padding: 30px 0;
  background-color: #fff;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.content {
  margin-top: 59px;
  height: 100%;
  width: calc(100% - 60px);
  overflow: hidden;
  padding: 30px;
}
.btns {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}
/deep/ #btns .el-button {
  width: 10%;
  // background-color: #fff;
}
/deep/ .content::-webkit-scrollbar {
  width: 4px;
}
/deep/ .content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/deep/ .content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
