<template>
  <div class="pageContent">
    <!-- <noNavHeader title="欢迎注册" /> -->
    <!-- <div class="header">
      <div class="text" @click="returnPage">返回</div>
    </div> -->
    <div class="regContainer" :class="{ enRegContainer: isEnRegContainer }">
       <div class="all w">
      <div class="logo">
          <img src="../../../assets/configImages/dllogo.png" alt="" width="50%">
          <img src="../../../assets/configImages/hydl.png" alt="" width="50%">
        </div>
      <div class="box componentBackgroundColor">
        <!-- <el-row>
          <el-col>
            <h2 class="regTlt">欢迎注册</h2>
          </el-col> -->
        <!-- </el-row> -->
        <!-- <el-row> -->
          <!-- <el-col :span="24"> -->
            <h2 style="width: 100%;">
              <img src="../../../assets/configImages/hyzc.png" alt="" width="100%"></h2>
            <div class="content">
            <el-form
              label-position="right"
              :model="modelReg"
              :rules="rulesReg"
              ref="regForm"
              :label-width="labelWidth"
              class="clsRuleReg"
            >
              <!-- <el-form-item label="姓名" prop="mobile" class="whiteEleLabel" v-if="regType==='mobile'">
                <el-input class="eleInputText"  :placeholder="请输入用户名" v-model="modelReg.mobile"></el-input>
              </el-form-item>-->
              <el-form-item
                label=""
                prop="mobile"
                class="whiteEleLabel"
                v-if="regType === 'mobile'"
              >
               <div class="lable">
                    账号 
                    <span>Account number</span>
                </div>
                <el-input
                  class="eleInputText"
                  placeholder="请输入您的手机号"
                  v-model="modelReg.mobile"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('message.Password')" prop="pwd" class="whiteEleLabel">
              <el-input class="eleInputText" type="password" :placeholder="$t('message.eight_20_alphanumerical_characters')"  v-model="modelReg.pwd"></el-input>
            </el-form-item>
            <el-form-item :label="$t('message.Confirm_Password')" prop="rePwd" class="whiteEleLabel">
              <el-input class="eleInputText" type="password" :placeholder="$t('message.Please_retype_the_password')" v-model="modelReg.rePwd"></el-input>
              </el-form-item>-->
              <!-- <el-form-item
                label="图形验证码"
                prop="imgValidateCode"
                class="whiteEleLabel"
              >
                <el-input
                  class="eleInputText imgYzmInput"
                  placeholder="请输入图形验证码"
                  v-model="modelReg.imgValidateCode"
                >
                  <template slot="append">
                    <img class="imgYzm" @click="getCap" :src="imgData" />
                  </template>
                </el-input>
              </el-form-item> -->

              <el-form-item
                v-if="shiyan == 1 || shifou"
                label=""
                prop="msgValidateCode"
                class="whiteEleLabel"
              >
              <div class="lable lable1">
                    {{this.regType === 'mobile' ? '短信验证码' : '邮箱验证码'}}
                    <span>{{this.regType === 'mobile' ? 'SMS verification code' : 'Email verification code'}}</span>
                </div>
                <el-input
                  class="eleInputText msgYzmInput"
                  :placeholder="
                    this.regType === 'mobile'
                      ? '请输入短信验证码'
                      : '请输入邮箱验证码'
                  "
                  v-model="modelReg.msgValidateCode"
                >
                </el-input>
                <el-button
                    @click="getMsgYzm('regForm')"
                    :disabled="btnDisenable"
                    class="btnGetYzm"
                    :class="{ enBtnGetYzm: isEnBtnGetYzm }"
                    >{{ btnMsgYzmVal }}</el-button
                  >
              </el-form-item>
              <el-form-item label="" prop="pwd" class="whiteEleLabel">
                <div class="lable lable2">
                    登录密码 
                    <span>Login password </span>
                </div>
                <el-input
                  class="eleInputText"
                  :type="newPwdText"
                  placeholder="请输入您的登录密码"
                  v-model="modelReg.pwd"
                >
                  <img
                    slot="suffix"
                    :src="closeEye"
                    v-if="passNewType"
                    @click="showPwd1"/>
                  <img slot="suffix" :src="openEye" v-else @click="hidePwd1"
                /></el-input>
              </el-form-item>
              <el-form-item label="" prop="rePwd" class="whiteEleLabel">
                <div class="lable lable2">
                    确认密码 
                    <span>Login password </span>
                </div>
                <el-input
                  class="eleInputText"
                  :type="newAginPwdText"
                  placeholder="请输入您的登录密码"
                  v-model="modelReg.rePwd"
                >
                  <img
                    slot="suffix"
                    :src="closeEye"
                    v-if="newPwdType"
                    @click="showPwd2"/>
                  <img slot="suffix" :src="openEye" v-else @click="hidePwd2"
                /></el-input>
              </el-form-item>
              <!-- <el-form-item label="邀请码" prop="userCode" class="whiteEleLabel">
                <el-input
                  class="eleInputText"
                  type="text"
                  placeholder="请输入邀请码"
                  v-model="modelReg.userCode"
                ></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="用户名称" prop="userName" class="whiteEleLabel">
                <el-input
                  class="eleInputText"
                  type="text"
                  placeholder="请输入用户名称"
                  v-model="modelReg.userName"
                ></el-input>
              </el-form-item> -->
              <!-- <el-form-item prop="isAgree" class="userProtocols">
                 <el-checkbox v-model="modelReg.isAgree">阅读并同意 <span class="blueProtocol" @click="dialogVisible=true">【入驻平台注册协议】</span></el-checkbox>
              </el-form-item>
              <el-form-item prop="checked">
                 <el-checkbox v-model="modelReg.checked">阅读并同意<span class="blueProtocol" @click="dialogVisible1=true">【线上交易管理办法】</span></el-checkbox>
              </el-form-item> -->
              
              <el-form-item id="btn1">
                <el-button
                  type="danger"
                  @click="submitRegForm('regForm')"
                  :disabled="btnEnable"
                  >注  册</el-button
                >
              </el-form-item>
              <el-form-item class="xyContent">
                <!-- <el-checkbox v-model="modelReg.isAgree" @change="isAgreeChange"
                  >我已阅读并同意</el-checkbox
                > -->
                <div class="xyWrap">
                  <div>
                  <span class="goLogin" style="margin-left: 0">请阅读并同意</span
                ><span class="blueProtocol" @click.stop="agreementOpen"
                  >《风险告知书》</span
                >
                </div>
                <span class="goLogin"
                  >已有账号去<b
                    @click="goLogin()"
                    style="color:#0F348F;cursor: pointer;"
                    >登录</b
                  ></span
                >
                </div>
              </el-form-item>
            </el-form>
            </div>
          <!-- </el-col> -->
        <!-- </el-row> -->
      </div>
       </div>
      
    </div>
    <!-- <div class="hasAccount">
      <p class="title">已有赣稀所账号？</p>
      <p class="login" @click="goLogin()">登录</p>
      <p class="title contract">有问题请联系客服</p>
      <p>电话：<span class="telephone">0797-8155657</span></p>
    </div> -->
    <div>
        <el-dialog
          v-dialogDrag
          title=""
          :visible.sync="dialogVisible"
          width="70%"
        >
          <UserProtocol></UserProtocol>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="
                modelReg.isAgree = false;
                dialogVisible = false;
              "
              class="dialogBtn"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="
                modelReg.isAgree = true;
                dialogVisible = false;
              "
              class="dialogBtn"
              >确认</el-button
            >
          </span>
        </el-dialog>
      </div>
      <div id="dialog1">
        <el-dialog
          custom-class="regDialog"
          v-dialogDrag
          title=""
          :visible.sync="dialogVisible1"
          width="1100px"
          :before-close="cancelBtn"
          :close-on-click-modal="false"
        >
          <!-- <manageRules></manageRules>
          <span slot="footer" class="dialog-footer">
            <el-button
              @click="modelReg.checked=false;dialogVisible1 = false"
              class="dialogBtn"
            >取消</el-button>
            <el-button
              type="primary"
              @click="modelReg.checked=true;dialogVisible1 = false"
              class="dialogBtn"
            >确认</el-button>
          </span> -->
          <!-- <div class="top">
            <p
              style="
            font-size: 14pt;
            line-height: 115%;
            margin: 0pt;
            text-align: center;
          "
            >
              <span
                style="font-family: 宋体; font-size: 14pt; font-weight: bold"
                >东亚畜牧现货产品交易所有限公司</span
              >
            </p>
            <p
              style="
            font-size: 12pt;
            line-height: 115%;
            margin: 0pt;
            text-align: center;
          "
            >
              <span
                style="font-family: 宋体; font-size: 12pt; font-weight: bold"
                >风险告知书</span
              >
            </p>
          </div> -->
          <UserProtocol
            @cancelBtn="cancelBtn"
            @enters="enters"
            @iconClick="iconClicks"
            ref="UserProtocols"
          ></UserProtocol>
          <div class="btns" id="btns">
            <el-button type="info" @click="cancelBtn">取消</el-button>
            <el-button
              type="primary"
              id="enterBtn"
              @click="enters"
              :disabled="enterDisabled"
              >{{ enterName }}</el-button
            >
          </div>
          <!-- <QluserProtocol v-if="!isHaiBei"></QluserProtocol> -->
        </el-dialog>
      </div>
  </div>
</template>
<script>
import md5 from "js-md5";
// import UserProtocol from "./UserProtocol";
import manageRules from "./manageRules";
import noNavHeader from "../../base/header/noNavHeader";
import UserProtocol from "./UserProtocol";
import QluserProtocol from "./QluserProtocol";
export default {
  name: "Register",
  components: {
    // UserProtocol,
    manageRules,
    noNavHeader,
    QluserProtocol,
    UserProtocol
  },
  data() {
    let validateRePwd = (rule, value, callback) => {
      if (value !== this.modelReg.pwd) {
        callback(new Error("两次输入密码不一致").message);
      } else {
        callback();
      }
    };
    let validateAgree = (rule, value, callback) => {
      console.log(value);
      if (value == false) {
        callback(new Error("阅读并同意入市协议"));
      } else {
        callback();
      }
    };
    let validateChecked = (rule, value, callback) => {
      if (value === false) {
        callback(new Error("阅读并同意入市协议"));
      } else {
        callback();
      }
    };
    let validateMsgCaptcha = (rule, value, callback) => {
      protocol.param_validMobileCaptcha.param.capid = this.modelReg.mobile;
      protocol.param_validMobileCaptcha.param.capcode = value;
      http.postFront(protocol.param_validMobileCaptcha).then(response => {
        let { code, msg, values } = response.data;
        if (code == 1) {
          callback();
        } else {
          callback(new Error(msg).message);
        }
      });
    };
    return {
      interval: null,
      time: window.g.AGREEMENT_TIME || 10000,
      ProtocolFlag: false,
      enterDisabled: true,
      enterName: "我已阅读",
      regType: "mobile",
      slideText: "向右滑",
      imgData: "",
      btnMsgYzmVal: "短信验证码",
      newPwdText: "password",
      newAginPwdText: "password",
      passNewType: true,
      newPwdType: true,
      closeEye: require("../../../assets/images/closeeye.png"),
      openEye: require("../../../assets/images/openeye.png"),
      btnDisenable: false,
      dialogVisible: false,
      dialogVisible1: false,
      readonly: false,
      btnEnable: false,
      modelReg: {
        mobile: "",
        pwd: "",
        rePwd: "",
        imgValidateCode: "",
        msgValidateCode: "",
        inviteCode: "",
        isAgree: false,
        userCode: "",
        userName: "",
        checked: false
      },
      shifou: true,
      shifou1: 1,
      shiyan: null,
      rulesReg: {
        mobile: [
          { required: true, message: "请输入您的账号", trigger: "blur" },
          { pattern: /^1\d{10}$/, message: "手机号格式不正确", trigger: "blur" }
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱账号",
            trigger: "blur"
          },
          {
            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message: "请输入正确格式的邮箱账号",
            trigger: "blur"
          }
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            message: "请输入长度是8-20位的数字、字母组合密码",
            trigger: "blur"
          }
        ],
        rePwd: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur"
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            message: "请输入长度是8-20位的数字、字母组合密码",
            trigger: "blur"
          },
          { validator: validateRePwd, trigger: "blur" }
        ],
        imgValidateCode: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur"
          }
        ],
        msgValidateCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
          // { validator: validateMsgCaptcha, trigger: 'blur' }
        ],
        inviteCode: [
          {
            required: true,
            message: "请填写邀请码",
            trigger: "blur"
          }
        ],
        isAgree: [{ validator: validateAgree, trigger: "change" }],
        // userCode: [
        //   { required: true, message: "请输入邀请码", trigger: "blur" }
        // ],
        // checked: [{ validator: validateChecked, trigger: "blur" }],
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" }
        ]
      },
      labelWidth: "125px",
      isEnRegContainer: false,
      isEnBtnGetYzm: false,
      registerLabelSpan: 4
    };
  },

  mounted() {
    // this.getCap();
    if (this.$route.params.id != "info") {
      this.modelReg.inviteCode = this.$route.params.id;
      this.readonly = true;
    }
    this.setLanguageCss();
  },

  methods: {
    // 协议关闭
    iconClicks() {
      this.dialogVisible1 = false;
    },
    agreementOpen() {
      var that = this;
      console.log("打开", window.g.AGREEMENT_TIME);
      this.dialogVisible1 = true;
      console.log(this.$refs.UserProtocols);

      // this.$nextTick(() => {
      //   that.$refs.UserProtocols.agreementOpen();
      // });
      // var time = window.g.AGREEMENT_TIME;

      // this.interval = setInterval(() => {
      //   that.time = Number(that.time) - 1000;
      //   if (that.time <= 0) {
      that.enterName = "我已阅读";
      clearInterval(that.interval);
      that.enterDisabled = false;
      //   } else {
      //     that.enterDisabled = true;
      //     that.enterName = `(${Number(that.time) / 1000}s)`;
      //   }
      // }, 1000);
    },
    isAgreeChange(val) {
      console.log(val);
    },
    cancelBtn() {
      // this.modelReg.checked = false
      this.dialogVisible1 = false;
      this.time = window.g.AGREEMENT_TIME;

      clearInterval(this.interval);
    },
    enters() {
      this.ProtocolFlag = true;
      this.modelReg.isAgree = true;
      this.dialogVisible1 = false;
    },
    returnPage() {
      this.$router.push("/login");
    },
    param_selectFirmType(item) {
      protocolFwd.param_selectFirmType.param.firmId = item;
      http.postFront(protocolFwd.param_selectFirmType).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          window.sessionStorage["userType"] = value.firmType;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showPwd1() {
      this.passNewType = false;
      this.newPwdText = "text";
    },
    hidePwd1() {
      this.passNewType = true;
      this.newPwdText = "password";
    },
    showPwd2() {
      this.newPwdType = false;
      this.newAginPwdText = "text";
    },
    hidePwd2() {
      this.newPwdType = true;
      this.newAginPwdText = "password";
    },
    onSuccess() {
      console.log("滑动成功");
      this.slideText = "成功";
    },
    onFail() {
      console.log("滑动失败");
      this.slideText = "向右滑";
    },
    onRefresh() {
      console.log("点击了刷新");
      this.slideText = "向右滑";
    },
    changeRegType(regType) {
      if (regType == "mobile") {
        this.shifou = false;
        this.shifou1 = 1;
      } else {
        this.shifou = true;
        this.shifou1 = 2;
      }
      this.regType = regType;
      this.$refs["regForm"].resetFields();
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.btnMsgYzmVal = "重新发送" + e + "秒";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.btnMsgYzmVal = vueThis.$t("message.Get_verification_code");
        vueThis.btnDisenable = false;
      }
    },
    // 发送验证码
    getMsgYzm(formName) {
      this.$refs[formName].validateField(this.regType, valid => {
        if (!valid) {
          protocol.param_authn_authn_sendCaptcha.param.loginAccount = this.modelReg.mobile;
          protocol.param_authn_authn_sendCaptcha.param.id = 2;
          protocol.param_authn_authn_sendCaptcha.param.mark = null;
          http
            .postFront(protocol.param_authn_authn_sendCaptcha)
            .then(response => {
              let { code, msg, values } = response.data;
              this.$EL_MESSAGE(response.data.message);
              this.$options.methods.setTime(60, this);
              this.btnDisenable = true;
            });

          // http.postFront(protocol.param_phoneIsUsed).then(response => {
          //   let { code, msg, values } = response.data;
          //   if (code === "-55422") {
          //     this.$EL_MESSAGE(msg);
          //   } else {
          //
          //     let that = this;
          //

          //   }
          // });
        }
      });
    },
    getCap() {
      http.getRes(protocol.param_getCaptcha).then(response => {
        this.imgData = "data:image/png;base64," + response.data.value.data;
        protocol.param_validImgCaptcha.param.capid = response.data.value.id;
      });
    },
    submitRegForm(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.ProtocolFlag, "this.ProtocolFlag");
          if (!this.ProtocolFlag) {
            this.$EL_MESSAGE("请阅读并同意风险告知书");
            return;
          }
          this.btnEnable = true;
          // if(this.slideText!='成功'){
          //   return this.$EL_MESSAGE('请重新移动滑块')
          // }
          // protocol.param_adminRegister2.param.captchaCode = this.modelReg.imgValidateCode;
          protocol.param_adminRegister2.param.loginAccount = this.modelReg.mobile;
          protocol.param_adminRegister2.param.captchaId =
            protocol.param_validImgCaptcha.param.capid;
          protocol.param_adminRegister2.param.code = this.modelReg.msgValidateCode;
          protocol.param_adminRegister2.param.password = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" + this.modelReg.pwd
          );

          // protocol.param_adminRegister.param.userCode = this.modelReg.userCode;
          protocol.param_adminRegister2.param.userName = this.modelReg.userName;
          protocol.param_adminRegister2.param.createWay = 2;
          // protocol.param_adminRegister2.param.lnvitationCode = this.modelReg.userCode
          // protocol.param_adminRegister2.param.marketId = 28;
          protocol.param_adminRegister2.param.firmTypeId = 1;
          protocol.param_adminRegister2.param.registerWay = 1;
          http
            .postFront(protocol.param_adminRegister2)
            .then(response => {
              let { code, message, value } = response.data;
              if (code != 0) {
                this.$EL_MESSAGE(message);
                setTimeout(() => {
                  that.btnEnable = false;
                }, 2000);
                // this.getCap();
                return;
              } else {
                this.btnEnable = false;
                this.$EL_MESSAGE(message);
                // 登录成功之后跳转到首页
                this.$router.push("/login");
              }
            })
            .catch(err => {
              this.btnEnable = false;
            });
        } else {
          return false;
        }
      });
    },
    goLogin() {
      this.$router.push("/login");
    },
    //根据语言改变样式
    setLanguageCss() {
      //防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          localStorage.setItem("labelWidth", "180px");
          localStorage.setItem("isEnRegContainer", true);
          localStorage.setItem("isEnBtnGetYzm", true);
          localStorage.setItem("registerLabelSpan", 8);
          this.labelWidth = localStorage.labelWidth;
          this.isEnRegContainer = localStorage.isEnRegContainer;
          this.isEnBtnGetYzm = localStorage.isEnBtnGetYzm;
          this.registerLabelSpan = parseInt(localStorage.registerLabelSpan);
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ #btn1 .el-button {
  background-color: #0F348F !important;
  border: 0;
  height: 48px;
}
/deep/ .el-button--info {
  background-color: #909399;
}
/deep/ .el-button--primary {
  background-color: #0F348F !important;
}
.btns {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}
/deep/ #btns .el-button {
  width: 10%;
  // background-color: #fff;
}
/deep/ #dialog1 .el-dialog {
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
  min-width: 1000px !important;
}
/deep/ #dialog1 .el-dialog__wrapper {
  overflow-y: hidden !important;
}
/deep/ .el-dialog::-webkit-scrollbar {
  width: 4px;
}
/deep/ .el-dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/deep/ .el-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.pageContent {
  background: url("../../../assets/configImages/new_login_img.jpg") center
    center no-repeat;
  width: 100%;
  height: 100vh;
  // background: url('../../../assets/configImages/login_bg.png') center center no-repeat;
  // background-size: cover;
  // background-repeat:no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  overflow: hidden;
}
$commonColor: #ffffff;
$componentBgColor: #344072;
$tipColor: #1ba9cd;
.logo{
  width: 40%;
  display: flex;
  align-items: center;

}
.all{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}
.all /deep/ .el-form-item__content{
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  line-height: normal;
  margin-left: 0px !important;
}
.all .el-form-item{
  margin-bottom: 0px;
}
.all /deep/ .el-input__inner {
  border-radius: 0;
    border: none;
    padding: 0;
    border-bottom: 1px solid #DCDFE6;
}
.all .el-form-item__label{
  text-align: left;
}
.xyWrap{
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  // padding-right: 54px;
}
.xyContent{
  /deep/ .el-form-item__content{
    padding-top: 0;
  }
}
.lable{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 20px;
  color: #1C1C1C;
  padding-bottom: 10px;
  // line-height: 66px;
  span{
    font-weight: 400;
  }
  span::before{
    position: absolute;
    display: inline-block;
    content: '*';
    color: red;
    /* margin-right: 10px; */
    top: 30px;
    left: 38px;
    font-size: 23px;
  }
}
.lable1{
  span::before{
    left: 98px;
  }
}
.lable2{
  span::before{
    left: 78px;
  }
}
.box {
  /* margin: 10% 0 5% 55%; */
  width: 40%;
  box-sizing: border-box;
  /* height: 450px; */
  /* position: absolute;
  top: 18%;
  right: 4%; */
  border-radius: 20px;
  background-color: #ffffff;
  padding-bottom: 24PX;
  /* box-shadow: 0px 0px 2px #666666; */
}
.content{
  width: 100%;
  padding: 0 50px;
  padding-top: 25px;
  box-sizing: border-box;
  padding-top: 0;
}
.regContainer {
 width: 100%;
 overflow: auto;
  // min-width: 1800px;
  height: 100vh;
  /* margin-top: -60px; */
  /* background: url("../../../assets/configImages/login_bg.png") center center no-repeat; */
  background: url("../../../assets/configImages/new_login_img.jpg") center
    center no-repeat;
  background-size: 100% 100vh;
  /* background-size: cover; */
}
.goLogin {
  font-size: 16px;
  margin-left: 127px;
  color: #474747;
  font-weight: 500;
}
.hasAccount {
  margin-top: 50px;
  line-height: 35px;
}
.hasAccount * {
  color: #333333;
  font-size: 14px;
}
.hasAccount .title {
  font-weight: 700;
}
.hasAccount .contract {
  margin-top: 20px;
}
.hasAccount .login {
  font-weight: 700;
  color: rgba(5, 64, 106, 1);
  cursor: pointer;
}
.hasAccount .telephone {
  color: orange;
}
div.enRegContainer {
  width: 800px;
}
div.enRegContainer .el-input {
  width: 80%;
}
.regTlt {
  font-size: 22px;
  margin: 0 35px 20px 35px;
  text-align: left;
  line-height: 80px;
  color: #666666;
  border-bottom: 1px solid #f2f2f2;
}

.el-input,
button {
  width: 100%;
}
.tip {
  margin-left: 10%;
  color: $tipColor;
}
// button {
//   background-color: #f56c6c;
//   border-radius: 6px;
// }
.el-row {
  margin-top: 10px;
}
.spanBottom {
  margin-bottom: 20px;
}
input.inputtext {
  background-color: $componentBgColor;
}
.imgYzm {
  height: 86%;
  width: 115px;
  cursor: pointer;
}
.userProtocal {
  color: #99ccff;
}
.btnGetYzm {
  position: absolute;
  bottom: 8px;
  width: 115px;
  color: #0F348F;
  right: 0px;
  border-radius: 8px;
  border: 1px solid #0F348F;
}
button.enBtnGetYzm {
  padding: 12px 10px;
}
.dialogBtn {
  width: 40%;
}
.blueProtocol {
  color: #0F348F;
  font-weight: 700;
  cursor: pointer;
  // text-decoration: underline;
}
</style>
<style>
.imgYzmInput .el-input-group__append {
  padding: 0 !important;
}
.userProtocols {
  margin-bottom: 5px;
}
</style>
